.page-template-measurement {
    header {
        padding: 2rem 0 0 0;
    }

    .btn {
        min-width: auto;
    }

    table {
        font-size: 1rem;
        cursor: pointer;

        th {
            font-weight: normal;
        }

        a {
            //display: table-row;
            color: inherit;
        }

        .edit-icon {
            color: $secondary;
            font-size: 1rem;
        }

        .change-up {
            color: $primary;
        }

        .change-down {
            color: $green;
        }

        .no-change {
            color: $grey2;
        }

        a:hover {
            text-decoration: none;
        }
    }

    .deleteMeasurment {
        padding: 0;
        color: $primary;
        text-align: center;
        display: block;
        margin: 1rem auto 0 auto;

        height: auto;
        max-width: none;
        background: none;
        border: none;
        text-transform: inherit;
        font-weight: inherit;
        appearance: none;

        &:hover,
        &:focus,
        &:active {
            text-decoration: underline;
        }
    }
}
