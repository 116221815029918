.page-template-cms-contact {
    header {
        padding: 0 0 2rem 0;

        @include media-breakpoint-up(md) {
            padding: 2rem 0;
        }
    }

    h2 {
        text-align: center;
    }

    // h3 {
    //     font-size: 1.5rem;
    //     margin-bottom: 1rem;
    //     color: $navy-blue;
    //     padding-right: 2rem;
    //     position: relative;

    //     span.collapse-arrow {
    //         display: flex;
    //         align-items: center;
    //         position: absolute;
    //         right: 0;
    //         top: 0;
    //         color: $blue;
    //         font-size: 1.5rem;
    //         height: 100%;
    //         transition: all 0.3s ease-in-out;

    //         &.open {
    //             transform: rotate(180deg);
    //         }
    //     }
    // }

    ol {
        font-size: 1.5rem;
        padding: 0 0 0 2rem;

        li {
            position: relative;
            padding: 0 0 1rem 1rem;
        }
    }

    .faq-section {
        padding: 1rem 0 1.375rem 0;

        .collapse-item {
            border-bottom: 1px solid rgba($green, 0.1);

            &:last-child {
                border-bottom: none;
            }
        }

        h3 {
            margin: 1rem 0;
            padding-right: 2rem;
            position: relative;

            button{
                background: none;
                border: none;
                color: $secondary;
                font-weight: 500;
                text-align: left;
                display: block;
                width: 100%;
                outline: none;
            }

            span.collapse-arrow {
                display: flex;
                align-items: center;
                position: absolute;
                right: 0;
                top: 0;
                color: $green;
                height: 100%;
                transition: all 0.3s ease-in-out;

                &.open {
                    transform: rotate(180deg);
                }
            }
        }

        .card {
            border: none;
            margin-bottom: 1rem;

            .card-body {
                background: $light-grey2;
                // box-shadow: 0px 0px 10px rgba($black,0.15);
                border-radius: 10px;
                padding: 1rem;
               
            
                @include media-breakpoint-up(lg) {
                    padding: 2rem;
                }

                ul{
                    padding: 0 0 0  1.25rem;
                }
            }
        }
    }
}
