.page-template-login{
	position: relative;

	section{
		padding: 2rem 0;
	}

	.btn{
		&.btn-primary {
			cursor: pointer;
			background: linear-gradient(
				to right,
				$dark-green 0%,
				$light-green 50%,
				$dark-green 100%
			);
			background-size: 200% auto;			
	
			&.outline {
				border: 1px solid $secondary;
				background: $white;
				color: $secondary;
			}
	
			&.disabled {
				background: $grey;
			}
		}
	}
}