@import './elements/range';

.form-group {
    label {
        color: $secondary;
        font-size: rem-calc(14);

        sup {
            color: $primary;
        }
    }
    .form-control {
        padding: 0;
        border-radius: 10px;
        background: $input-bg;
        border: 1px solid $input-border;
        height: auto;

        &.is-invalid {
            border: 1px solid $primary;
        }

        input {
            border: none;
            background: none;
            padding: 0.5rem 1rem;

            &::-webkit-date-and-time-value{ 
                text-align:left;
            }
        }

        textarea {
            border: none;
            background: none;
            padding: 0.5rem 1rem;
        }

        .input-group-text {
            background: none;
            border: none;
        }

        select {
            border: none;
        }
    }

    .invalid-feedback {
        color: $primary;

        li {
            list-style: none;
        }
    }

    svg,
    .SVGInline {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        padding: 1rem;
        pointer-events: none;
        max-width: 3.5rem;
        z-index: 3;
    }

    &.is-invalid {
        .invalid-feedback {
            display: block;
        }
    }
}

select {
    padding: 0.5rem 1rem !important;
    border: 1px solid $input-border;
    border-radius: 10px !important;
    appearance: none;
    height: auto !important;
    cursor: pointer;

    &::-ms-expand {
        display: none;
    }
}

.custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: $secondary;
    background-color: $secondary;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: $very-light-green;
    border-color: $very-light-green;
}

.custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba($secondary, 0.25);
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: $very-light-green;
}

.alert {
    border-radius: 10px;

    &.alert-info,
    &.alert-success {
        border: 1px solid $secondary;
        background-color: $green-opacity;
        color: $green;
    }

    &.alert-danger {
        border: 1px solid $primary;
        background-color: $orange-opacity;
        color: $primary;
    }

    &.alert-holiday-change-meal {
        border: 1px solid $secondary;
        background-color: $green-opacity;
    }

    h4 {
        font-size: 1rem;
        color: inherit;
        font-weight: bold;
        margin: 0 0 0.5rem 0;
    }

    ul {
        font-size: 1rem;
        list-style: disc;
        padding-left: 1.25rem;
        margin: 0;

        li {
            padding: 0;
            &:before {
                display: none;
            }
        }
    }
}
