@charset "UTF-8";

.page-template-fav-meals {
    height: 100%;

    main {
        position: relative;
        min-height: 100vh;
        background-color: $white;

        .modalContainer{
            transition: transform 500ms;
            transform: translateX(0);
            height: 100%;
        }

        .showModal {
            transform: translateX(-50%);
            overflow: hidden !important;
        }

        .w-md-auto {
            @include media-breakpoint-up(md) {
                width: auto !important;
            }
        }

        label{
			font-size: 1rem;
			color: $font-color;
			text-align: left;
		}

        .add-remove-fav{
            position: absolute;
            top: 0;
            right: 0;
            margin-top: -4px;
            margin-right: 12px;
            padding: 0;
            border: none;
            background: none;
    
            width: 40px;
            height: 40px;
    
            img{
                width: 100%;
                height: auto;
            }
        }

        .meal{
            position: relative;
            margin: 0 0 30px 0;

            @include media-breakpoint-down(sm){ 
                padding-left: 6px;
                margin: 0 0 12px 0;

                &:nth-child(2n+1){
                    padding-left: 16px;
                    padding-right: 6px;
                }
            }

            .white-box{
                position: relative;
                padding: 0 20px 20px 20px;
                overflow: hidden;

                .embed-responsive{
                    margin: 0 -20px 20px -20px;
                    width: calc(100% + 40px);

                    .embed-responsive-item{
                        background-size: cover;
                        background-position: center;
                    }
                }

                .meal-title{
                    height: 40px;
                    overflow: hidden;
                    margin: 0 0 16px 0;

                    // trim to 2 lines
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;  

                    @include media-breakpoint-up(md){
                        height: 48px;
                    }
                }
            } 
        }

        .top-bar {
            background: $white;
            h1 {
                font-family: 'Ubuntu', sans-serif;
                font-size: 30px;
                font-weight: 400;

                strong {
                    font-weight: 500;
                }

                .btn{
                    position: absolute;
                    left: 0;

                    @include media-breakpoint-down(md){
                        position: relative;
                        float: left;
                        left: initial;
                        font-size: 0;
                        min-width: 0;
                        padding: 0 24px;
                        margin-right: 0; 
                    }
                }
            }
        }

        .meals-filters {
            position: sticky;
            top: 0;
            background: $white;
            padding: 0;
            z-index: 10;
            overflow-x: auto;
            border-bottom: 1px solid #B4B4B4;

            &.active {
                transition: all 0.4s ease-in-out;
            }

            &.hidden {
                top: 0;
                transition: all 0.4s ease-in-out;
            }

            .container {
                padding: 0;
            }

            ul {
                text-align: center;
                white-space: nowrap;
                margin: 0;
                padding: 0;
                overflow-x: auto;

                &::-webkit-scrollbar {
                    display: none; // Safari and Chrome
                }

                @include media-breakpoint-up(sm) {
                    overflow: hidden;
                }

                .nav-item {
                    display: inline-block;
                }
            }

            .nav-link {
                padding: 12px 12px 10px 12px;
                color: $grey;
                margin: 0;
                font-size: 16px;
                font-weight: 400;
                white-space: nowrap;
                cursor: pointer;
                border-bottom: 2px solid $white;

                @include media-breakpoint-up(md) {
                    padding: 12px 24px 10px 24px;
                }

                &:active,
                &:hover {
                    color: $grey;
                }

                &.active {
                    color: $primary;
                    border-color: $primary;
                }
            }
        }

        //meal-details
        .meal-details {
            position: relative;
            padding-top: 0;
            padding-bottom: 0;

            @include media-breakpoint-up(md){
                padding-top: 1.5rem;
            }

            .embed-responsive{
                margin: 0 -15px;
                width: calc(100% + 30px);
                border-radius: 0;

                @include media-breakpoint-up(md){
                    margin: 0;
                    width: 100%;
                    border-radius: 10px;
                }

                .video{
                    background-color: $grey;
                }

                .embed-responsive-item{
                    background-size: cover;
                    background-position: center;
                }

                .label{
                    display: inline-block;
                    font-size: rem-calc(14);
                    padding: 0.25rem 1rem;
                    border-radius: rem-calc(10);
                    border: 1px solid $dark-grey;
                    background: $white;
                    color: $dark-grey;
                }

                .diet-phase{
                    display: inline-block;
                    font-size: rem-calc(14);
                    padding: 0.25rem 1rem;
                    border-radius: rem-calc(10);
                    border: 1px solid;
                    background: $white;
        
                    @media (max-width: 370px) {
                        font-size: rem-calc(13);
                    }
        
                    &.start, &.exit{
                        border-color: $orange-yellow;
                        color: $orange-yellow;
                    }
        
                    &.fast{
                        border-color: $purple;
                        color: $purple;
                    }
        
                    &.health{
                        border-color: $green;
                        color: $green;
                    }
                }
            }

            .meal-info{
                position: relative;
                padding-top: 24px;
                font-size: 14x;
                height: 100%;

                @include media-breakpoint-up(md){
                    font-size: 18px;
                    padding-top: 0;
                }

                h1{
                    font-family: 'Ubuntu', sans-serif;
                    color: $black;
                    font-weight: 400;
                    font-size: rem-calc(30);
                }

                .macro-and-kcal{
                    background: $green-opacity;
                    padding: 1rem;
                    border-radius: $borderRadius;
                    margin: 0 0 24px 0;
                    color: $green;
                    font-size: 12px; 
            
                    h3{
                        font-size: 16px;
                        color: $green;
                        font-weight: 400;

                        strong{
                            font-weight: 600;
                        }
                    }

                    hr{
                        border-top: 1px solid rgba($additional-light-green, 0.5);
                    }
            
                    .border-right {
                        border-right: 1px solid rgba($additional-light-green, 0.5) !important;
                    }
                }
            }

            .dish{
                @include media-breakpoint-up(md){
                    border-top: 1px solid $light-grey;
                    margin-top: 24px;
                    padding-top: 32px;
                }

                h2{
                    color: $black;
                    font-size: 24px;
                    font-weight: 400;
                }
            }

            .ingredients {
                font-size: 18px;
                list-style: none;
                margin: 0 0 2rem 0;
                padding: 0;

                li {
                    position: relative;
                    margin: 0 0 16px 0;
                    padding-left: 0;
                    cursor: pointer;
                }
            }

            .recipe {
                background: $light-grey2;
                padding: 20px;
                margin: 0 0 2rem 0;
                border-radius: $borderRadius;

                @include media-breakpoint-up(md){
                    margin: 0 0 1rem 0;
                }

                h4{
                    font-family: 'Ubuntu', sans-serif;
                    color: $grey;
                    font-weight: 400;
                    font-size: 18px;
                    margin: 0 0 1rem 0;
                }

                ol {
                    font-size: 15px;
                    padding: 0 0 0 20px;
                    margin: 0;
                    
                    li {
                        position: relative;
                        margin-bottom: 16px;

                        &:last-child{
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }

        .search {
            padding: 1rem 0 0 0;

            h3 {
                font-size: 20px;
                font-weight: 600;
            }

            hr {
                margin: 32px 0;
            }

            label {
                font-weight: 500;
            }
        }
    }
}
