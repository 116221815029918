.page-template-cms-article {
    header {
        padding: 2rem 0 0 0;

        @include media-breakpoint-up(md) {
            padding: 2rem 0;
        }

        .category {
            font-size: rem-calc(14);
            display: inline-block;
            padding: 0.35rem 1rem;
            border-radius: $borderRadius;
            color: $white;
            background: $grey;
            margin: 0 0 1rem;

            &.dieta {
                background-color: $green;
            }

            &.motywacja {
                background-color: $purple;
            }

            &.porady {
                background-color: $orange;
            }

            &.wykłady {
                background-color: $orange-yellow;
            }
        }

        h1 {
            font-size: 1.75rem;
            font-family: 'Ubuntu', sans-serif;

            @include media-breakpoint-up(md) {
                font-size: 2.25rem;
                text-align: center;
            }
        }
    }

    .article-img{
        border-radius: 10px;
        overflow: hidden;
        
        @include media-breakpoint-up(md) {
            margin-bottom: 2rem;
        }

    }

    .embed-responsive{
        margin: 2rem 0;
    }

    .blockquote {
        position: relative;
        margin-left: 3.5rem;
        padding-left: 1rem;
        border-left: 2px solid $blue;

        &:before {
            content: '\201D';
            position: absolute;
            top: -2rem;
            left: -3.5rem;
            color: $blue;
            font-size: 6.25rem;
        }
    }

    p.sign {
        position: relative;
        text-align: right;
        margin: 1rem 0 1rem 0;
        color: $blue;
    }

    .article-lead,
    .article-summary {
        margin: 2rem auto;
    }

    hr {
        //max-width: 700px;
        margin-left: auto;
        margin-right: auto;
    }

    .faq-section {
        padding: 1rem 0 1.375rem 0;

        .collapse-item {
            border-bottom: 1px solid rgba($green, 0.1);

            &:last-child {
                border-bottom: none;
            }
        }

        h3 {
            margin: 1rem 0;
            padding-right: 2rem;
            position: relative;

            button{
                background: none;
                border: none;
                color: $secondary;
                font-weight: 500;
                text-align: left;
                display: block;
                width: 100%;
                outline: none;
            }

            span.collapse-arrow {
                display: flex;
                align-items: center;
                position: absolute;
                right: 0;
                top: 0;
                color: $green;
                height: 100%;
                transition: all 0.3s ease-in-out;

                &.open {
                    transform: rotate(180deg);
                }
            }
        }

        .card {
            border: none;
            margin-bottom: 1rem;

            .card-body {
                background: $light-grey2;
                // box-shadow: 0px 0px 10px rgba($black,0.15);
                border-radius: 10px;
                padding: 1rem;
               
            
                @include media-breakpoint-up(lg) {
                    padding: 2rem;
                }

                ul{
                    padding: 0 0 0  1.25rem;
                }
            }
        }
    }

    .cms-content {
        margin-left: auto;
        margin-right: auto;

        h1 {
            text-align: center;
        }

        h2 {
            text-align: center;
        }

        h3 {
            margin: 0 0 1rem 0;
        }

        .blockquote {
            position: relative;
            margin-left: 3.5rem;
            padding-left: 1rem;
            border-left: 2px solid $blue;

            &:before {
                content: '\201D';
                position: absolute;
                top: -2rem;
                left: -3.5rem;
                color: $blue;
                font-size: 6.25rem;
            }
        }

        img {
            display: block;
            margin: 0 auto;
            max-width: 100%;
            height: auto;
        }

        ul {
            padding: 0 0 0 1.25rem;
            list-style: disc;

            li {
                position: relative;
                font-size: 1rem;
                padding: 0;                
            }
        }

        ol {
            font-size: 1rem;
            padding: 0 0 0 1.25rem;

            li {
                padding: 0;
            }
        }

        .faq-section {
            padding: 1rem 0 1.375rem 0;
    
            .collapse-item {
                border-bottom: 1px solid rgba($green, 0.1);
    
                &:last-child {
                    border-bottom: none;
                }
            }
    
            h3 {
                margin: 1rem 0;
                padding-right: 2rem;
                position: relative;
    
                span.collapse-arrow {
                    display: flex;
                    align-items: center;
                    position: absolute;
                    right: 0;
                    top: 0;
                    color: $green;
                    height: 100%;
                    transition: all 0.3s ease-in-out;
    
                    &.open {
                        transform: rotate(180deg);
                    }
                }
            }
    
            .card {
                border: none;
                margin-bottom: 1rem;
    
                .card-body {
                    background: $white;
                    box-shadow: 0px 0px 10px rgba($black,0.15);
                    border-radius: 10px;
                    padding: 1rem;
                   
                
                    @include media-breakpoint-up(lg) {
                        padding: 2rem;
                    }
    
                    ul{
                        padding: 0 0 0  1.25rem;
                    }
                }
            }
        }

        .pageActions {
            text-align: center;

            a[role='button'] {
                @extend .btn;
                @extend .btn-blue;
            }
        }

        aside {
            &.right {
                float: right;
                margin-left: 1rem;
                margin-bottom: 1rem;
            }

            &.left {
                float: left;
                margin-right: 1rem;
                margin-bottom: 1rem;
            }
        }

        .table{
            th, td {
                padding: 0.5rem;
                font-size: 14px;
                word-wrap: break-word;
                overflow-wrap: break-word;
                hyphens: auto;
            }

            p, ol, ul, li{
                font-size: 14px;
                word-wrap: break-word;
                overflow-wrap: break-word;
                hyphens: auto;
            }
        }
    }
}
