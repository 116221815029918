.page-template-diet-settings {
    // overflow: hidden;

    .diet-settings-bottom-push{
        height: rem-calc(98);

        @include media-breakpoint-up(sm) {
            display: none;
        }
    }

    main {
        background-color: $light-grey;
        padding: 0;
        min-height: 100vh;

        @include media-breakpoint-up(sm) {
            padding: 4rem 0;
            min-height: auto;
        }
    }

    .full-screen {
        display: flex;
        width: 100%;
        // min-height: calc(100vh - 2rem);
        min-height: 100vh;
        flex-direction: column;

        @include media-breakpoint-up(sm) {
            min-height: auto;
        }

        &.container{
            min-height: calc(100vh - 3rem);
        }
    }

    .full-screen2 {
        min-height: calc(100vh - 50px - 3rem);
        display: flex;
        margin-top: rem-calc(-98);

        @include media-breakpoint-up(sm) {
            min-height: auto;
            margin-top: 0;
        }
    }

    .full-screen3 {
        margin-top: rem-calc(-98);
        min-height: calc(100vh - 98px - 52px);
        display: flex;

        @include media-breakpoint-up(sm) {
            min-height: auto;
            margin-top: 0;
        }
    }

    .action-buttons{
        position: sticky;
        top: calc(100vh - 98px);
        z-index: 10;

        width: 100%;
        left: 0;
        padding: 2rem 0 1rem 0;
        background-image: linear-gradient(
            to bottom,
            rgba($light-grey, 0) 0%,
            $light-grey 25%,
            $light-grey 100%
        );

        @include media-breakpoint-up(sm) {
            display: none;
        }
    }

    .action-buttons2{
        display: none;
        
        @include media-breakpoint-up(sm) {
            display: block;
            position: relative;
            top: initial;
            padding: 0;
            background-image: none;
            margin-top: 2rem;
        }
    }

    

    .logo{
        max-width: rem-calc(200);
    }

    h1 {
        font-family: 'Ubuntu', sans-serif;
        font-size: rem-calc(18);
        color: $primary;
        text-transform: uppercase;
        font-weight: 400;
        margin: 0 0 1rem 0;
        display: none;

        @include media-breakpoint-up(sm) {
            display: block;
        }
    }

    p {
        font-size: rem-calc(20);
        font-style: italic;
        color: $dark-grey;

        &.small {
            font-size: rem-calc(16);
            color: $grey;
        }

        &.advice {
            color: $green;
            font-size: rem-calc(18);
            padding: 0 0 0 2.5rem;
            background-image: url('../../assets/icons/advice.svg');
            background-position: top left;
            background-repeat: no-repeat;
            margin-top: 3rem;
        }

        &.acceptance{
            font-style: normal;
            font-size: rem-calc(13);
        }
    }

    .progress {
        border-radius: rem-calc(10);
        margin: 1rem 0 2rem 0;
        background: rgba($primary, 0.15);
        height: rem-calc(4);

        .progress-bar {
            background: $primary;
            border-radius: rem-calc(10);
        }
    }

    h2 {
        color: $black;
        font-size: rem-calc(36);
        margin-bottom: 1.5rem;

        &.acceptance{
            font-family: 'Berkshire Swash', sans-serif;
        }
    }

    p.recommended {
        margin-left: 2.5rem;
    }

    .card {
        padding: 1rem;
        border: none;
        border-radius: rem-calc(10);

        h2 {
            font-size: rem-calc(20);
            color: $secondary;
        }

        h3 {
            color: $grey;
            font-size: rem-calc(24);

            .big-number {
                font-size: rem-calc(55);
                color: $primary;
                vertical-align: baseline;
            }
        }
    }

    .animated {
        animation-duration: 0.3s;
    }

    .animated-fast {
        animation-duration: 0.2s;
    }

    .fadeInLeft {
        animation-name: fadeInLeftNew;
    }

    @keyframes fadeInLeftNew {
        from {
            opacity: 0;
            transform: translate3d(-50%, 0, 0);
        }

        to {
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
    }

    .fadeInRight {
        animation-name: fadeInRightNew;
    }

    @keyframes fadeInRightNew {
        from {
            opacity: 0;
            transform: translate3d(50%, 0, 0);
        }

        to {
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
    }

    .fadeOutLeft {
        animation-name: fadeOutLeftNew;
    }

    @keyframes fadeOutLeftNew {
        from {
            opacity: 1;
        }

        to {
            opacity: 0;
            transform: translate3d(-50%, 0, 0);
        }
    }

    .fadeOutRight {
        animation-name: fadeOutRightNew;
    }

    @keyframes fadeOutRightNew {
        from {
            opacity: 1;
        }

        to {
            opacity: 0;
            transform: translate3d(50%, 0, 0);
        }
    }

    .hide {
        opacity: 0;
    }

    .btn {
        position: relative;
        border-radius: rem-calc(25);
        border: none;
        max-width: 20rem;
        max-width: 100%;
        height: rem-calc(50);
        padding: 0 2rem;
        font-size: 1rem;
        font-weight: 400;
        text-align: center;
        text-transform: uppercase;
        overflow: hidden;
        transition: 0.5s;
    }

    .btn-primary {
        padding: 0 4rem;
    }

    .btn-secondary {
        padding: 0 1rem;
        min-width: rem-calc(50);
        color: $primary;
        background: transparent;
        border: 1px solid $primary;

        &.with-arrow {
            &::after {
                content: '';
                position: absolute;
                left: 1rem;
                top: 0;
                width: 1rem;
                height: 100%;
                background-image: url(../../assets/icons/arrow_back.svg);
                background-position: center;
                background-repeat: no-repeat;
            }
        }

        &:hover,
        &:active {
            color: $primary !important;
            background-color: transparent !important;
            border: 1px solid $primary !important;
        }
    }

    .btn-info {
        padding: 0;
        min-width: rem-calc(30);
        height: rem-calc(30);
        color: $secondary;
        background-color: transparent;
        border: 1px solid $secondary;
        text-transform: lowercase;

        &:hover,
        &:active {
            color: $secondary !important;
            background-color: transparent !important;
            border: 1px solid $secondary !important;
        }
    }

    // styles from _form.scss
    .input-group {
        &.form-control {
            background: $white;
            padding: 0;
            border-radius: rem-calc(10) !important;
        }
    }
    
    input[type='text'],
    input[type='number'],
    input[type='date'] {
        font-size: rem-calc(22);
        padding: 0.75rem 1.5rem;
        min-height: rem-calc(57);
    }
    
    input[type='date'] {
        -webkit-appearance: none;
        background-image: url(../../assets/icons/calendar.svg);
        background-size: 1.5rem;
        background-repeat: no-repeat;
        background-position: right 1.7rem center;
        
        &::-webkit-calendar-picker-indicator{
            opacity: 0;
            color: transparent;
            background-color: transparent;
        }

        &::-webkit-inner-spin-button {
            display: none;
            -webkit-appearance: none;
        }

        &::-webkit-date-and-time-value{ 
            text-align:left;
            line-height: 24px;
        }
    }
    
    .ruler-slider {
        margin-left: -15px;
        margin-right: -15px;
        margin-bottom: 2rem;
        background: $white;
        padding: 1rem 0 0 0;
        text-align: center;
    
        h3 {
            color: $grey;
            font-size: rem-calc(24);
            margin-bottom: 1rem;
    
            .big-number {
                font-size: rem-calc(30);
                color: $secondary;
                font-weight: bold;
                vertical-align: baseline;
            }
        }
    
        .slide-ruler_box_1Le {
            &::before {
                background-color: $green !important;
            }
        }
    }
    
    textarea {
        background: $white !important;
        font-size: 1.375rem !important;
        padding: 0.375rem 1.5rem !important;
        border: none !important;
        height: 10rem;
    }
    
    .input-group-text {
        font-size: rem-calc(20) !important;
        background-color: $white;
        color: $grey;
        border: none;
        border-radius: rem-calc(10);
        padding: rem-calc(13) 1.5rem !important;
    }
    
    .input-placeholder {
        display: block;
        width: 100%;
        padding: 0.75rem 1.5rem;
        font-size: 1.375rem;
        line-height: 1.5;
        border-radius: 0.25rem;
    }
    
    .form-group {
        position: relative;
        margin-bottom: 1.5rem;
    
        &.is-invalid {
            margin-left: -15px;
            margin-right: -15px;
            padding: 1rem;
            background: $very-light-orange;
        }
    
        svg,
        .SVGInline {
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            padding: 1rem;
            pointer-events: none;
            max-width: 3.5rem;
        }
    }
    
    // select {
    //     background: $white !important;
    //     font-size: 1.375rem !important;
    //     padding: 0.375rem 1.5rem !important;
    //     border: 2px solid $navy-blue !important;
    //     appearance: none;
    //     height: auto !important;
    //     cursor: pointer;
    
    //     &::-ms-expand {
    //         display: none;
    //     }
    // }
    
    .form-control {
        border: none;
        height: auto;
        color: $dark-grey;
    
        @media print {
            border: 1px solid $light-grey;
        }
    
        &.is-invalid {
            border: 2px solid $orange;
            color: $orange !important;
            background-image: url(../../assets/icons/error.svg);
            background-size: auto;
            background-position: left 1rem center;
            background-repeat: no-repeat;
            
            input[type='text'],
            input[type='number'],
            input[type='date'] {
                padding: 0.75rem 1.5rem 0.75rem 3.5rem !important;
            }
            
        }
    
        &:focus {
            box-shadow: none;
        }
    }
    
    label,
    .label {
        width: 100%;
        font-size: rem-calc(20);
        font-weight: 400;
        color: $dark-grey;
        margin-bottom: 1rem;
    
        .secondary {
            color: $secondary;
        }
    
        &.form-check-label,
        &.form-switch-label {
            position: relative;
            background: $white;
            box-shadow: 0px 4px 8px rgba($black, 0.1);
            border-radius: rem-calc(10);
            margin: 0 0 rem-calc(14) 0;
            padding: 1rem 3.5rem 1rem 1rem;
    
            color: $font-color;
            font-size: rem-calc(16);
            font-weight: 400;
    
            &::before {
                content: '';
                position: absolute;
                left: 1rem;
                bottom: 0;
                width: calc(100% - 2rem);
                height: 1rem;
                box-shadow: 0px 6px 10px rgba($light-green, 0.5);
                opacity: 0;
                border-radius: rem-calc(10);
            }
    
            &::after {
                content: '';
                position: absolute;
                top: 50%;
                margin-top: rem-calc(-15);
                right: 1rem;
                width: rem-calc(30);
                height: rem-calc(30);
                background: $white;
                border-radius: rem-calc(15);
                background-image: url(../../assets/icons/check.svg);
                background-position: top 5px center;
                background-repeat: no-repeat;
                opacity: 0;
            }
    
            strong {
                color: $secondary;
                font-weight: 500;
                font-size: rem-calc(18);
            }
    
            &.active {
                background: linear-gradient(
                    to right,
                    $dark-green 0%,
                    $light-green 100%
                );
                color: $very-light-green;
    
                strong {
                    color: $white;
                }
    
                &::before {
                    opacity: 1;
                }
    
                &::after {
                    opacity: 1;
                }
            }
    
            &.rounded {
                padding: 0.30rem 0;
                text-align: center;
                margin-right: 0.5rem;
                width: 34px;
                border-radius: rem-calc(20) !important;
    
                strong {
                    font-size: rem-calc(16);
                }
    
                &::before {
                    border-radius: rem-calc(20);
                }
    
                &::after {
                    opacity: 0;
                }
    
                @include media-breakpoint-up(sm) {
                    padding: 0.5rem 0;
                    width: 40px;
                }
            }
        }

        &.custom-control-label{
            font-size: rem-calc(14);
            color: $secondary;
        }
    
        &.form-switch-label {
            padding: 0;
        }
    
        &.is-invalid {
            padding: 1rem 0 1rem 2.5rem !important;
        }
    
        sup {
            color: $magenta;
        }
    }
    
    .not-label {
        color: inherit;
        font-size: 1em;
        text-align: inherit;
        margin: initial;
        font-weight: inherit;
    }
    
    .form-check {
        padding: 0;
    
        input {
            opacity: 0;
        }
    
        .checkmark {
            display: none;
        }
    
        .form-control.is-invalid {
            padding: 0;
            border: none;
            background-image: none;
        }
    }
    
    .form-switch {
        position: relative;
        padding: 1rem 5rem 1rem 0;
        border-top: 1px solid $light-grey;
        margin: 0;
    
        &:last-child {
            border-bottom: 1px solid $light-grey;
        }
    
        input {
            display: none;
        }
    
        .switch {
            position: absolute;
            width: 3.75rem;
            height: 2.125rem;
            top: 0.75rem;
            right: 0;
            background-color: $grey;
            transition: 0.4s;
            border-radius: 2.125rem;
            cursor: pointer;
    
            &:before {
                position: absolute;
                content: '';
                height: 1.625rem;
                width: 1.625rem;
                left: 0.25rem;
                bottom: 0.25rem;
                background-color: $white;
                -webkit-transition: 0.4s;
                transition: 0.4s;
                border-radius: 50%;
                box-shadow: 0.25rem 0.25rem 0.25rem 0px rgba($black, 0.2);
            }
    
            &.placeholder {
                background-color: $light-grey;
            }
        }
    
        input:checked ~ .switch {
            background-color: $blue;
    
            &:before {
                transform: translateX(1.625rem);
            }
        }
    }
    
    .custom-range {
        position: relative;
        -webkit-appearance: none;
        appearance: none;
        width: 100%;
        height: 46px;
        outline: none;
        padding: 0;
    
        &::-webkit-slider-runnable-track {
            width: 100%;
            height: 10px;
            cursor: pointer;
            background: linear-gradient(
                to right,
                $dark-green 0%,
                $green var(--value),
                rgba($green, 0.15) var(--value),
                rgba($green, 0.15) 100%
            );
            border-radius: 5px;
        }
    
        &::-moz-range-track {
            width: 100%;
            height: 10px;
            cursor: pointer;
            background: linear-gradient(
                to right,
                $dark-green 0%,
                $green var(--value),
                rgba($green, 0.15) var(--value),
                rgba($green, 0.15) 100%
            );
            border-radius: 5px;
        }
    
        &::-webkit-slider-thumb {
            -webkit-appearance: none;
            appearance: none;
            width: 30px;
            height: 30px;
            background: $white;
            border: 4px solid $orange;
            cursor: pointer;
            margin-top: -10px;
        }
    
        &::-moz-range-thumb {
            width: 22px;
            height: 22px;
            background: $white;
            border: 4px solid $orange;
            cursor: pointer;
            margin-top: -10px;
        }
    
        &::before {
            content: attr(min);
            position: absolute;
            left: 0;
            bottom: -0.5rem;
            font-size: rem-calc(14);
            color: $secondary;
        }
    
        &::after {
            content: attr(max);
            position: absolute;
            right: 0;
            bottom: -0.5rem;
            font-size: rem-calc(14);
            color: $secondary;
        }
    }

    .alert{
        h2{
            font-size: 1.5rem;
            margin-bottom: 1rem;
            color: $primary;
        }
        
        p{
            font-style: inherit;
            font-size: 1rem;
            color: inherit;
        }
    }
    
    .invalid-feedback {
        margin-top: 0.5rem;
        color: $primary;
        font-size: 100%;
        font-size: rem-calc(16);
    
        li {
            list-style: none;
        }
    }
    
    ul.errors {
        text-align: left;
        font-size: 1.5rem;
        color: $red;
    }

    .custom-control{
        padding-left: 2rem;
    }

    .custom-control-input:focus ~ .custom-control-label::before {
        box-shadow: none;
    }

    .custom-control-label::before {
        left: -2rem;
        width: 1.5rem;
        height: 1.5rem;
        border: 2px solid $secondary;
    }

    .custom-control-label::after {
        left: -2rem;
        width: 1.5rem;
        height: 1.5rem;
    }

    .custom-control-input{
        &:focus ~ .custom-control-label::before {
            border-color: $secondary;
        }

        &:active ~ .custom-control-label::before {
            background-color: $green-opacity;
            border-color: $secondary;
        }

        &:checked ~ .custom-control-label::before {
            border-color: $secondary;
            background-color: $secondary;
        }
    }
    
    // disable webkit autofill yellow bg
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        box-shadow: 0 0 0 30px $light-grey inset;
        -webkit-box-shadow: 0 0 0 30px $light-grey inset;
    }
}
