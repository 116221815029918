.page-template-cart {

    header {
        padding: 0 0 2rem 0;

        @include media-breakpoint-up(md) {
            padding: 2rem 0;
        }
    }

    main{
        background: $white;
    }

    section {
        padding: 0 0 2rem 0;
        border-bottom: none;
    }

    hr{
        border-top: 1px solid #3FC769;
        margin-bottom: 2rem;
    }

    .line{
        position: relative;
        text-align: center;
        margin: 1rem 0;
        font-size: 14px;
        color: $green;
        
        span{
            position: relative;
            display: inline-block;
            background: $white;
            padding: 0 1rem;
        }

        &::before{
            content: '';
            position: absolute;
            width: 100%;
            height: 1px;
            background: $light-grey3;
            top: 50%;
            left: 0;
        }
    }

    h2 {
        padding: 1rem 15px;
        //margin: 0;
        border-bottom: 1px solid $secondary;
        color: $secondary;
        margin-right: -15px;
        margin-left: -15px;

        &.no-border{
            padding: 0 0 1rem 0;
            margin: 0;
            border-bottom: none;
            margin-right: 0;
            margin-left: 0;
        }
    }

    label{
        font-size: 1rem;
        text-align: left;
    }

    .custom-checkbox, .custom-radio{
        label{
            color: $font-color;
        }
    }

    .btn-link{
        color: $secondary;
    }

    .price {
        text-align: right;
        color: $secondary;
    }

    .form-check {
        border: none;
    }

    .payment-method-form {
        .collapse.show {
            display: inline;
        }
        
        .payment-icon{
            width: auto;
            height: 24px;
            margin: 4px 4px 0 0;

            @include media-breakpoint-up(md) {
                margin: 0 4px 4px 0;
            }
        }
    }

    span.collapse-arrow {
        display: inline-block;
        color: $secondary;
        transition: all 0.3s ease-in-out;

        &.open {
            transform: rotate(180deg);
        }
    }

    .cart-product {
        .row {
            border-bottom: 1px solid $light-grey;
            padding-top: 1rem;
            padding-bottom: 1rem;
            font-size: 1rem;
        }

        .button-remove {
            position: relative;
            width: 1.5rem;
            height: 1.5rem;
            background: none;
            text-align: center;
            transition: all ease-in-out 0.3s;
            padding: 0;
            min-width: auto;

            &:hover, &:active, &:focus {
                background: none;
                box-shadow: none !important;
            }

            &::after {
                display: none;
            }

            .SVGInline svg{
                width: 1.5rem;
                height: 1.5rem;
            }
        }

        .amount {
            display: flex;
            align-items: center;
            color: $secondary;
        }

        .button-increase,
        .button-decrease {
            width: 1.5rem;
            height: 1.5rem;
            min-width: initial;
            background: none;
            color: $secondary;
            padding: 0;
            border-radius: 0;
            border: none;
            line-height: 1.5rem;
            text-align: center;
            font-weight: 400;
            margin: 0 1rem;
            padding: 0 0.5em;

            &:active,
            &:focus,
            &:active:focus {
                background: none;
                box-shadow: none;
                color: $secondary;
            }

            &::after {
                display: none;
            }
        }

        .button-increase {
            font-weight: 500;
        }
    }

    .cart-summary {
        .row {
            border-bottom: 1px solid $light-grey;
            padding-top: 1rem;
            padding-bottom: 1rem;
            font-size: 1rem;

            &.delivery-option-price {
                border-bottom: 2px solid $secondary;
            }
        }

        .collapse .row.summary-product {
            padding-left: 1rem;
        }
    }

    .card-deck.multi-products {
        flex-flow: row nowrap;
        margin-right: -15px;
        margin-left: -15px;
        overflow-x: auto;

        &:after {
            content: '';
            flex: 0 0 15px;

            @include media-breakpoint-up(sm) {
                flex: 0;
            }
        }

        .product {
            margin-left: 15px;
            min-width: 85%;

            @include media-breakpoint-up(sm) {
                min-width: auto;
            }
        }
    }

    .product {
        border: 2px solid $secondary;
        text-align: center;
        border-radius: 0.5rem;
        padding: 1rem;

        .card-header,
        .card-body,
        .card-footer {
            padding: 0;
            border: none;
            background: transparent;
        }

        .product-name {
            display: block;
            color: $dark-grey;
            font-weight: 500;
            font-size: 2rem;
        }

        .product-description,
        .product-save {
            display: block;
            color: $dark-grey;
            font-size: 2rem;
        }

        .product-old-price {
            display: block;
            color: $grey;
            font-size: 2rem;
            text-decoration: line-through;
        }

        .product-price {
            font-weight: 500;
            display: block;
            color: $green;
            font-size: 3rem;
        }
    }

    .btn {
        &.btn-link {
            color: $grey;
            text-decoration: underline;
        }
    }

    .safe-shopping{
        .safe-shopping-container{
            border: 1px solid $light-grey3;
            border-radius: 16px;
            padding: 24px 32px 12px 32px;
            text-align: center;
            display: inline-block;

            &:after{
                content: attr(data-title);
                position: absolute;
                top: 0;
                left: 50%;
                transform: translate(-50%, -50%);
                background-color: $white;
                color: $dark-grey;
                padding: 0 12px;
                font-size: 18px;
            }

            img{
                margin: 0 4px 12px 4px;
            }
        }
    }

    .payment-method {
        // min-height: 10.25rem;
        border-radius: 0.5rem;
        border: 1px solid $light-grey;
        box-shadow: 0px 3px 6px 0px rgba($light-grey, 0.5);
        margin: 0 0 1rem 0;
        padding: 1rem;
        transition: border-color 0.3s ease-in-out;
        cursor: pointer;

        // @include media-breakpoint-up(lg) {
        //     min-height: 8rem;
        // }

        p {
            margin: 0;
        }

        .payment-logo {
            transition: all 0.3s ease-in-out;
            margin: 0 auto;
            padding: 0;

            @include media-breakpoint-up(sm) {
                padding: 1rem;
            }

            @include media-breakpoint-up(md) {
                max-height: 3.5rem;
                padding: 0;
            }
        }

        .payment-online-method-list {
            display: block;
            margin: 0;
            padding: 0;
            list-style: none;
            line-height: 1;
        }

        .payment-online-method-item,
        .payment-online-method-item-et-al {
            display: inline-block;
            margin: 0.25rem 0.7rem 0.25em 0;
        }

        .payment-online-method-logo {
            height: 1.75rem;
        }

        .payment-online-method-item-et-al {
            --size: 0.5rem;
            top: calc(1.75em / 2 - var(--size));
        }

        .payment-online-method-item-et-al,
        .payment-online-method-item-et-al::before,
        .payment-online-method-item-et-al::after {
            position: relative;
            width: 0;
            height: 0;
            border: currentColor solid calc(var(--size) / 2);
            border-radius: 50%;
            background: currentColor;
        }

        .payment-online-method-item-et-al::before,
        .payment-online-method-item-et-al::after {
            position: absolute;
            content: '';
            top: calc(-1 * var(--size) / 2);
        }

        .payment-online-method-item-et-al::before {
            left: calc(var(--size));
        }

        .payment-online-method-item-et-al::after {
            left: calc(var(--size) * 2.5);
        }

        .logo-placeholder {
            display: block;
            margin: 0 auto;
            width: 100%;
            height: 4.75rem;
        }

        &:hover {
            border-color: $secondary;
        }
    }
}

.product-details {
    text-align: center;
    padding: 4rem 0;
    height: calc(100vh - 1rem - 2px);

    @include media-breakpoint-up(sm) {
        height: calc(100vh - 3.5rem - 2px);
    }

    .product-name {
        display: block;
        color: $dark-grey;
        font-weight: 500;
        font-size: 2rem;
    }

    .product-description,
    .product-save {
        display: block;
        color: $dark-grey;
        font-size: 2rem;
    }

    .product-old-price {
        display: block;
        color: $grey;
        font-size: 2rem;
        text-decoration: line-through;
    }

    .product-price {
        font-weight: 500;
        display: block;
        color: $green;
        font-size: 3rem;
    }
}
