#buy {
    border-bottom: none;

    section {
        padding: 1rem 0;

        @include media-breakpoint-up(sm) {
            padding: 3.5rem 0;
        }

        &:last-child {
            border-bottom: none;
            padding-bottom: 0;
        }
    }

    h2 {
        margin: 0;
    }

    .card-deck.multi-products {
        flex-flow: row nowrap;
        margin-right: -15px;
        margin-left: -15px;
        overflow-x: auto;

        &:after {
            content: '';
            flex: 0 0 15px;

            @include media-breakpoint-up(sm) {
                flex: 0;
            }
        }

        .product {
            margin-left: 15px;
            min-width: 85%;

            @include media-breakpoint-up(sm) {
                min-width: auto;
            }
        }
    }

    .product {
        border: 2px solid $blue;
        text-align: center;
        border-radius: 0.5rem;
        padding: 1rem;
        overflow: hidden;

        @include media-breakpoint-up(sm) {
            text-align: left;
        }

        .card-header,
        .card-body,
        .card-footer {
            padding: 0;
            border: none;
            background: transparent;
        }

        .product-bestseller {
            position: absolute;
            top: 1.5rem;
            right: -2.25rem;
            background: $green;
            color: $dark-grey;
            text-transform: uppercase;
            text-align: center;
            transform: rotate(45deg);
            padding: 0.25rem 2rem;
            font-size: 1rem;

            @include media-breakpoint-up(sm) {
                font-size: 0.75rem;
                top: 1.25rem;
            }
        }

        .product-name {
            display: block;
            color: $dark-grey;
            font-weight: 400;
            font-size: 2rem;

            @include media-breakpoint-up(sm) {
                font-size: 1.125rem;
            }
        }

        .product-description,
        .product-save {
            display: block;
            color: $dark-grey;
            font-size: 2rem;

            @include media-breakpoint-up(sm) {
                font-size: 1.125rem;
            }
        }

        .product-old-price {
            display: block;
            color: $grey;
            font-size: 1.375rem;
            text-decoration: line-through;
            font-size: 2rem;

            @include media-breakpoint-up(sm) {
                font-size: 1.375rem;
            }
        }

        .product-price {
            font-weight: 500;
            display: block;
            color: $green;
            font-size: 3rem;
        }

        .btn {
            margin-top: 1rem;
        }
    }

    .product--blue {
        border-color: $blue;

        .product-bestseller {
            background: $blue;
            color: $white;
        }

        .product-name,
        .product-description,
        .product-save,
        .product-old-price,
        .product-price {
            color: $blue;
        }
    }

    .product--red {
        border-color: $red;

        .product-bestseller {
            background: $red;
            color: $white;
        }

        .product-name,
        .product-description,
        .product-save,
        .product-old-price,
        .product-price {
            color: $red;
        }
    }

    .product--black {
        border-color: $black;

        .product-bestseller {
            background: $black;
            color: $white;
        }

        .product-name,
        .product-description,
        .product-save,
        .product-old-price,
        .product-price {
            color: $black;
        }
    }
}
