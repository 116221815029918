.page-template-thnk-you {
    .hero {
        background: $white;
        padding: 3rem 0;
        background-image: url(../../assets/images/header.jpg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border: none;

        @include media-breakpoint-up(md) {
            padding: 6rem 0;
        }

        h1 {
            text-align: center;
            margin: 0;
        }
    }

    ol {
        padding: 0 0 0 2.5rem;
        counter-reset: item;
        list-style: none;

        

        li {
            position: relative;
            font-size: 18px;
            font-weight: 500;
            color: $primary;
            margin-bottom: 2rem;

            &::before{
                content: counter(item);
                counter-increment: item;
                position: absolute;
                top: 0;
                left: -2.5rem;

                width: 2rem;
                height: 2rem;
                border-radius: 50%;
                border: 1px solid #FFD1C5;
                text-align: center;
                line-height: 1.8rem;
            }

            p{
                color: $font-color;
                font-weight: 400;
                margin-bottom: 1rem;
            }
        }
    }

    .flyer {
        padding: 1.5rem;
        background: $light-grey2;
        border-radius: $border-radius;
        margin: 0 0 2rem 0;
    }

    hr {
        background-color: $additional-light-green;
    }

    .store-link {
        display: inline-block;
        margin: 1rem 1rem 0 0;
    }
}
