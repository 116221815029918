#main-menu {
    padding: 0;
    border-bottom: 1px solid $light-grey;
    z-index: 11;
    position: relative;
    
    .user-menu-collapse {
        flex-basis: 100%;
        flex-grow: 1;
        align-items: center;
    }

    nav.navbar {
        padding: 1rem;
        background: $white;
        transition: background-color 0.3s ease-in-out;
        border-bottom: none;

        a.btn,
        button {
            padding: 0 2rem;
            font-size: 1rem;
            height: 3rem;
        }

        .navbar-brand {
            padding-top: 0;
            padding-bottom: 0;

            img {
                width: 8rem;
                margin-bottom: -0.5rem;

                @include media-breakpoint-up(lg) {
                    margin-bottom: 0;
                }
            }
        }

        .dropdown {
            .dropdown-arrow {
                display: inline-block;
                transition: all 0.3s ease-in-out;
            }

            .dropdown-menu {
                font-size: 1.375rem;
                color: $black;

                opacity: 0;
                transition: opacity 0.3s ease-in-out;

                .dropdown-header {
                    font-size: 1rem;
                    color: $primary;
                }

                .dropdown-item {
                    padding: 0.25rem 1.5rem;
                    height: auto;
                    line-height: normal;
                    background: $white;

                    &:hover {
                        background-color: lighten($light-grey, 2%);
                    }
                }
            }

            &.show {
                .dropdown-arrow {
                    transform: rotate(180deg);
                }

                .dropdown-menu {
                    opacity: 1;
                }
            }
        }

        .navbar-nav {
            padding-top: 1rem;

            @include media-breakpoint-up(lg) {
                padding-top: 0;
            }
        }

        .navbar-toggler {
            padding: 0;
            outline: none;
            color: $black;
            transition: color 0.3s ease-in-out;
            border-radius: 0;

            .dropdown-arrow {
                color: $secondary;
                display: inline-block;
                transition: all 0.3s ease-in-out;
            }
        }

        .dropdown-toggler {
            background: none;
            padding: 0;
            margin-top: 0;
            color: $black;
            box-shadow: none;
            border-radius: 0;
            font-size: 1rem;
            height: 3rem;
            cursor: pointer;
            width: auto;
            font-weight: inherit;
            text-transform: inherit;
            border: none !important;
            min-width: auto;
            

            .dropdown-arrow {
                color: $secondary;
                display: inline-block;
                transition: all 0.3s ease-in-out;

                &.open {
                    transform: rotate(180deg);
                }
            }
        }

        .nav-link {
            font-size: 1rem;
            color: $black;
            white-space: nowrap;
            transition: color 0.3s ease-in-out;

            text-align: center;
            border-top: 1px solid rgba($white, 0.2);

            &:hover {
                background-color: rgba($white, 0.1);
                border-top: 1px solid rgba($white, 0.1);
            }

            &.active {
                color: $secondary;
            }

            @include media-breakpoint-up(lg) {
                padding: 0.5rem 1rem;
                border-top: none;

                &:hover {
                    border-top: none;
                    color: $secondary;
                }
            }
        }

        .user-menu-collapse {
            .nav-link {
                border-top: 1px solid rgba($grey, 0.1);

                &:hover {
                    background-color: rgba($light-grey, 0.5);
                    border-top: 1px solid rgba($grey, 0.05);
                }

                @include media-breakpoint-up(lg) {
                    border-top: none;

                    &:hover {
                        border-top: none;
                    }
                }
            }
        }

        .dropdown-toggler::after {
            background: transparent;
        }

        &.navbar-open {
            background-color: $secondary;

            @include media-breakpoint-up(lg) {
                background-color: $white;
            }

            .navbar-toggler {
                color: $white;
                background: transparent;

                .dropdown-arrow {
                    color: $white;
                    transform: rotate(180deg);
                }

                @include media-breakpoint-up(lg) {
                    color: $black;
                }
            }

            .dropdown-toggler {
                color: $white;
                background: transparent;

                .dropdown-arrow {
                    color: $white;
                }

                @include media-breakpoint-up(lg) {
                    color: $black;

                    .dropdown-arrow {
                        color: $secondary;
                    }
                }
            }

            button {
                color: $blue;
                background: $white;

                @include media-breakpoint-up(lg) {
                    color: $white;
                    background: $blue;
                }
            }

            .nav-link {
                color: $white;

                &.active {
                    color: $black;
                }

                @include media-breakpoint-up(lg) {
                    color: $black;
                }
            }
        }
    }

    nav.navbar-expand-md {
        @include media-breakpoint-up(lg) {
            // min-height: 6.5rem;
        }
    }
}

.nav-item {
    .subnav {
        padding: 0;
        position: relative;
        width: 100%;
        border-bottom: 0;
        background-color: rgba($white, 0.1);

        .container {
            padding: 0;
        }

        @include media-breakpoint-up(lg) {
            display: block;
            position: absolute;
            z-index: $zIndexMenu;
            left: 0;
            top: 85px;
            height: 0;
            padding: 0;
            background: $white;
            border-top: 1px solid $grey;
            transition: height 0.3s ease-in-out;
            overflow: hidden;
        }

        @include media-breakpoint-up(lg) {
            top: 104px;
        }

        .navbar-nav {
            padding-top: 0 !important;
        }

        .nav-item {
            .nav-link {
                color: $white !important;

                &.active {
                    color: $navy-blue !important;
                }

                @include media-breakpoint-up(lg) {
                    padding: 1.25rem !important;
                    color: $navy-blue !important;

                    &:hover {
                        color: $blue !important;
                    }

                    &.active {
                        color: $blue !important;
                    }
                }
            }

            &.active {
                .nav-link {
                    color: $navy-blue !important;

                    @include media-breakpoint-up(lg) {
                        color: $blue !important;
                    }
                }
            }
        }
    }

    &:hover {
        .subnav {
            @include media-breakpoint-up(lg) {
                height: 58.5px;
            }

            @include media-breakpoint-up(lg) {
                height: 73px;
            }
        }
    }
}

.dropdown-item {
    padding: 0;

    a {
        display: block;
        width: 100%;
        padding: 0.25rem 1.5rem;
        font-size: 1.375rem;
        color: $navy-blue;
        text-decoration: none;
        transition: all 0.3s ease-in-out;

        &:hover {
            background: $light-grey;
            color: $blue;
        }
    }
}
