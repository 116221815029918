.page-template-hydration
{
    $dark-blue: #115281;
    $blue: #57AAE6;

    .full-screen {
        display: flex;
        width: 100%;
        min-height: calc(100vh - 4rem);
        flex-direction: column;

        @include media-breakpoint-up(sm) {
            min-height: auto;
        }

        &.container{
            min-height: calc(100vh - 4rem);
        }
    }

    main{
        padding: 2rem 0;
        min-height: 100vh;
       
        @include media-breakpoint-up(md) {
            // min-height: 100vh - navbar height
            min-height: calc(100vh - 99.5px);
        }
    }

    .btn-history{
        position: absolute;
        top: 0;
        right: rem-calc(15);
        display: inline-block;
        width: rem-calc(50);
        height: rem-calc(50);
        padding: rem-calc(9);
        background: $white;
        border-radius: rem-calc(10);
        z-index: 10;

        @include media-breakpoint-up(md) {
            right: 0;
        }
    }

    .drop{
        position: relative;
        display: inline-block;

        img{
            position: relative;
            display: block;
            margin: 0 auto;
            opacity: 0.6;
            z-index: 1;

        }
        
        .value{
            position: absolute;
            top: 15%;
            left: 15%;
            width: 70%;
            height: 70%;
            border: 2px solid $blue;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;

            .waves{
                position: absolute;
                bottom: 0;
                width: 100%;
                height: 0%;
                max-height: 200%;
                opacity: 0.5;
                
                z-index: 0;
                background-image: url(../../assets/icons/hydration/waves.png);
                background-position: top right;
                transition: all 1s ease-out;

                animation-name: wave;
                animation-duration: 6s;
                animation-iteration-count: infinite;
                animation-timing-function: linear;

            }

            .waves2{
                position: absolute;
                bottom: 0;
                width: 100%;
                height: 0%;
                max-height: 200%;
                
                z-index: 0;
                background-image: url(../../assets/icons/hydration/waves.png);
                background-position: top left;
                transition: all 1s ease-out;

                animation-name: wave2;
                animation-duration: 3s;
                animation-iteration-count: infinite;
                animation-timing-function: linear;

            }

            @keyframes wave {
                from {
                    background-position: top right;
                }
                to {
                    background-position: top right 600px;
                }
            }

            @keyframes wave2 {
                from {
                    background-position: top left;
                }
                to {
                    background-position: top left 600px;
                }
            }

            p{
                text-align: center;
                font-size: rem-calc(24);
                color: $dark-blue;
                line-height: 1.2;
                font-weight: 500;
                margin: 0;
                z-index: 1;
            }
        }
    }

    .hydration-goal{
        position: relative;
        margin: -2rem 0 1rem 0;
        z-index: 10;
    }

    .btn-goal{
        display: inline-block;
        font-size: rem-calc(16);
        padding: 0.25rem 1rem;
        border-radius: rem-calc(10);
        border: 1px solid;
        background: $white;
        border-color: $white;
        color: $grey !important;
        min-width: auto;
        height: auto;

        &:hover, &:active, &:focus{
            background-color: $white !important;
            border-color: $white !important;
            box-shadow: 0 0 0 0.2rem rgba($blue, 0.5) !important;
        }

        .your-goal{
            color: $blue;
            font-weight: 500;
        }

        &.btn-placeholder {
            background: $light-grey;
            background-image: linear-gradient(
                to right,
                $light-grey 0%,
                darken($light-grey, 4%) 20%,
                $light-grey 40%,
                $light-grey 100%
            );
            background-repeat: no-repeat;
            background-size: 960px 480px;
    
            animation-duration: 1s;
            animation-fill-mode: forwards;
            animation-iteration-count: infinite;
            animation-name: placeholderShimmer;
            animation-timing-function: linear;
    
            color: transparent !important;

            .your-goal{
                color: transparent;
            }
        }
    }

    .btn-add{
        display: inline-block;
        font-size: rem-calc(16);
        padding: 0.75rem;
        margin: 1rem 0.5rem;
        border-radius: rem-calc(10);
        border: 1px solid;
        background: $white;
        border-color: $white;
        color: $grey !important;
        min-width: rem-calc(78);
        height: auto;
        box-shadow: 0 0 10px 0 $grey2;

        &:hover, &:active, &:focus{
            background-color: $white !important;
            border-color: $white !important;
            box-shadow: 0 0 0 0.2rem rgba($blue, 0.5) !important;
        }
        

        img{
            display: block;
            margin: 0 auto 0.5rem auto;
            width: rem-calc(35);
            height: rem-calc(75);
        }

        &.btn-placeholder {
            background: $light-grey;
            background-image: linear-gradient(
                to right,
                $light-grey 0%,
                darken($light-grey, 4%) 20%,
                $light-grey 40%,
                $light-grey 100%
            );
            background-repeat: no-repeat;
            background-size: 960px 480px;
    
            animation-duration: 1s;
            animation-fill-mode: forwards;
            animation-iteration-count: infinite;
            animation-name: placeholderShimmer;
            animation-timing-function: linear;
    
            color: transparent !important;
        }
    }


    .btn-link{
        text-decoration: underline;
        
        &:disabled{
            background: transparent;
            opacity: 0;
        }
    }

    .ct-chart {
        .ct-series {
            .ct-point {
                stroke: $blue;
            }
    
            .ct-area {
                fill: $blue;
            }
    
            .ct-bar {
                fill: $blue;
            }
        }
    }
}

.modal-dialog.hydration{

    h2 {
        font-size: rem-calc(20);
        color: $grey;
    }

    h3 {
        color: $grey;
        font-size: rem-calc(24);

        .big-number {
            font-size: rem-calc(55);
            color: $blue;
            vertical-align: baseline;
        }
    }

    .custom-range {
        position: relative;
        -webkit-appearance: none;
        appearance: none;
        width: 100%;
        height: 46px;
        outline: none;
        padding: 0;
    
        &::-webkit-slider-runnable-track {
            width: 100%;
            height: 10px;
            cursor: pointer;
            background: linear-gradient(
                to right,
                $blue 0%,
                $blue var(--value),
                rgba($grey, 0.15) var(--value),
                rgba($grey, 0.15) 100%
            );
            border-radius: 5px;
        }
    
        &::-moz-range-track {
            width: 100%;
            height: 10px;
            cursor: pointer;
            background: linear-gradient(
                to right,
                $blue 0%,
                $blue var(--value),
                rgba($grey, 0.15) var(--value),
                rgba($grey, 0.15) 100%
            );
            border-radius: 5px;
        }
    
        &::-webkit-slider-thumb {
            -webkit-appearance: none;
            appearance: none;
            width: 30px;
            height: 30px;
            background: $white;
            border: 4px solid $blue;
            cursor: pointer;
            margin-top: -10px;
        }
    
        &::-moz-range-thumb {
            width: 22px;
            height: 22px;
            background: $white;
            border: 4px solid $blue;
            cursor: pointer;
            margin-top: -10px;
        }
    
        &::before {
            content: attr(min);
            position: absolute;
            left: 0;
            bottom: -0.5rem;
            font-size: rem-calc(14);
            color: $grey2;
        }
    
        &::after {
            content: attr(max);
            position: absolute;
            right: 0;
            bottom: -0.5rem;
            font-size: rem-calc(14);
            color: $grey2;
        }
    }
}
