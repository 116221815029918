.btn {
    position: relative;
    border-radius: rem-calc(25);
    border: none;
    max-width: 20rem;
    max-width: 100%;
    min-width: rem-calc(180);
    height: rem-calc(36);
    padding: 0 2rem;
    font-size: 1rem;
    font-weight: 400;
    text-align: center;
    overflow: hidden;
    // transition: 0.5s;
    font-family: 'Ubuntu', sans-serif;

    @include media-breakpoint-up(sm) {
        padding: 0 4rem;
    }

    &:focus {
        box-shadow: none;
    }

    &.btn-primary {
        // color: $white !important;
        cursor: pointer;
        background: linear-gradient(
            to right,
            $dark-orange 0%,
            $light-orange 50%,
            $dark-orange 100%
        );
        background-size: 200% auto;

        &.with-arrow {
            &::after {
                content: '';
                position: absolute;
                right: 1rem;
                top: 0;
                width: 1rem;
                height: 100%;
                background-image: url(../assets/icons/arrow_next.svg);
                background-position: center;
                background-repeat: no-repeat;
            }
        }

        &.disabled {
            background: $very-light-orange;
        }
    }

    &.btn-outline-primary{
        background: transparent;
        color: $primary;
        border: 2px solid $primary;
        padding: 3px 20px;

        &:active{
            background-color: transparent !important;
            border-color: $primary !important;
            color: $primary !important;
        }

        &.active{
            color: $white !important;
        }
    }

    &.btn-secondary {
        // color: $white !important;
        cursor: pointer;
        background: linear-gradient(
            to right,
            $dark-green 0%,
            $light-green 50%,
            $dark-green 100%
        );
        background-size: 200% auto;

        &.with-arrow-next {
            &::after {
                content: '';
                position: absolute;
                right: 1rem;
                top: 0;
                width: 1rem;
                height: 100%;
                background-image: url(../assets/icons/arrow_next.svg);
                background-position: center;
                background-repeat: no-repeat;
            }
        }

        &.with-arrow-prev {
            &::after {
                content: '';
                position: absolute;
                left: 1rem;
                top: 0;
                width: 1rem;
                height: 100%;
                background-image: url(../assets/icons/arrow_next.svg);
                background-position: center;
                background-repeat: no-repeat;
                transform: rotate(180deg);
            }
        }

        &.outline {
            border: 1px solid $secondary;
            background: $white;
            color: $secondary;
        }

        &.disabled {
            background: $grey;
        }

        &.done {
            border: 1px solid $grey2;
            color: $secondary;
            background: transparent;
            background-image: url(../assets/icons/check-green.svg);
            background-repeat: no-repeat;
            background-position: left 1rem center;
            opacity: 1;
        }
    }

    &.btn-outline-secondary{
        background: transparent;
        color: $secondary;
        border: 1px solid $secondary;
        line-height: 34px;
        
        &:active{
            background-color: transparent !important;
            border-color: $secondary !important;
            color: $secondary !important;
        }

        &.active{
            color: $white !important;
        }

        &.with-arrow-prev {
            padding: 0 24px;

            @include media-breakpoint-up(lg) {
                padding: 0 4rem;
            }
            
            &::after {
                content: '';
                position: absolute;
                left: 0.75rem;
                top: 0;
                width: 24px;
                height: 100%;
                background-image: url(../assets/images/dayplan/arrow-right.svg);
                background-size: 100%;
                background-position: center;
                background-repeat: no-repeat;
                transform: rotate(180deg);

                @include media-breakpoint-up(lg){
                    left: 1rem;
                }
            }
        }
    }


    &.btn-info {
        padding: 0;
        min-width: rem-calc(30);
        height: rem-calc(30);
        color: $secondary;
        background-color: transparent;
        border: 1px solid $secondary;
        text-transform: lowercase;

        &:hover,
        &:active {
            color: $secondary !important;
            background-color: transparent !important;
            border: 1px solid $secondary !important;
        }
    }

    &.btn-arrow {
        position: relative;
        padding: 0 !important;
        color: $black;
        background-color: $white;
        font-weight: 400;
        border-radius: $border-radius;
        text-align: left;
        font-size: 16px;

        .SVGInline {
            color: $secondary;
            float: right;
        }
    }

    &.disabled {
        background: $grey;
    }

    &.min-width-none {
        min-width: initial;
    }

    &.btn-auto-width {
        padding: 0 0.625rem;
        min-width: rem-calc(36);
    }

    &.btn-back {
        width: auto;
        text-decoration: none;
        position: relative;

        .SVGInline {
            display: inline-block;
            position: absolute;
            left: 1rem;
        }

        svg {
            margin-right: 1rem;
            transition: all 0.5s ease-in-out;
        }

        &:hover {
            svg {
                animation: slide1 1s ease-in-out infinite;
            }
        }

        &:after {
            display: none;
        }
    }

    @keyframes slide1 {
        0%,
        100% {
            transform: translate(0, 0);
        }

        50% {
            transform: translate(0.625rem, 0);
        }
    }

    &.btn-placeholder {
        background: $light-grey;
        background-image: linear-gradient(
            to right,
            $light-grey 0%,
            darken($light-grey, 4%) 20%,
            $light-grey 40%,
            $light-grey 100%
        );
        background-repeat: no-repeat;
        background-size: 960px 480px;

        animation-duration: 1s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-name: placeholderShimmer;
        animation-timing-function: linear;

        color: transparent;

        &:before {
            display: none;
        }
    }

    &.auto-width {
        width: auto;
    }

    &:hover {
        background-position: right center;
    }

    &.btn-social{
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5 !important;
        border-radius: 20px;
        padding: 6px 10px 6px 36px;
        display: block;
        margin: 0.5rem auto;
        font-family: Arial, Helvetica, sans-serif;
        text-decoration: none;
        max-width: 300px;
    }

    &.facebook{
        background: #4267b2;
        color: $white;
        border: 1px solid #4267b2;
        background-image: url(../assets/icons/button_facebook_logo.svg);
        background-position: left 10px center;
        background-repeat: no-repeat;
    }

    &.google{
        background: $white;
        color: $black;
        border: 1px solid $light-grey3; 
        background-image: url(../assets/icons/button_google_logo.svg);
        background-position: left 10px center;
        background-repeat: no-repeat;
    }

    &.apple{
        background: $black;
        color: $white;
        border: 1px solid $black;
        background-image: url(../assets/icons/button_apple_logo.svg);
        background-position: left 10px center;
        background-repeat: no-repeat;
    }
}

a.btn {
    &.btn-primary,
    &.btn-secondary {
        line-height: rem-calc(36);
    }
}

.btn-group {
    width: 100%;
    max-width: 100%;

    @include media-breakpoint-up(sm) {
        max-width: 20rem;
    }

    .btn {
        padding: 0;
    }

    .btn-blue {
        background: transparent;
        color: $blue;
        border: 2px solid $blue;

        &.active {
            color: $white;
            background: $blue;
        }
    }
}

.btn-link {
    color: $grey;
    min-width: initial !important;
    padding: 0;
    border-radius: 0;
    cursor: pointer;

    &:hover,
    &:active {
        color: $grey;
    }
}
