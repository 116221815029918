.cachedDataMessage.alert
{
    position: fixed;
    left: 0;
    width: 100%;
    z-index: $zIndexCacheMessage;
    bottom: 0;
    margin-bottom: 0;
    border: none;
    border-radius: 0;
    color: $white;
    background: rgba($red, 0.9);
    transition: opacity 200ms ease-in;

    &::after
    {
        display: none;
    }

    &[hidden]
    {
        pointer-events: none;
        display: block !important;
        opacity: 0;
    }
}
