.Snackbar_snackbar-wrapper-bottom-center__21ghq{
    margin: 8px 15px !important;
    z-index: 30 !important;
}

.Snackbar_snackbar__GsYZl{
    background: linear-gradient(
        to right,
        $light-green 0%,
        $dark-green 100%
    ) !important;
    padding: 18px !important;
    border-radius: 10px !important;
    box-shadow: none !important;
    
    .Snackbar_snackbar__text__1Hx2a{
        padding: 0;

        p{
            margin: 0;
            color: $white;
            font-size: 18px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .btn-link{
            display: inline-block;
            font-size: 18px;
            font-weight: 400;
            line-height: 1;
            text-transform: none;
            color: $orange-yellow;
            padding: 0;
            margin: 0;
            width: auto;
            height: auto;
        }
    }

    .Snackbar_snackbar__close__NCHgT{
        display: none;
    }
}