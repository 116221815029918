@import 'variables';

//bootstrap
@import '~bootstrap/scss/bootstrap';
//fonts
@import url('https://fonts.googleapis.com/css?family=Ubuntu:400,500,700&display=swap&subset=latin-ext');
@import url('https://fonts.googleapis.com/css?family=Berkshire+Swash&display=swap&subset=latin-ext');

//functions
@import 'functions/rem-calc';

//vendor
@import 'vendor/snackbar';

//core stylesheet
@import 'layout';
@import 'navigation';
@import 'typography';
@import 'button';
@import 'form';
@import 'footer';
@import 'flashMessage';
@import 'cachedDataMessage';

//plugins
@import 'plugins/timeline';
@import 'plugins/chartist';
@import 'plugins/odometer';

//components
@import 'components/chart';
@import 'components/floating-action-button';
@import 'components/longInlineLoader';
@import 'components/developerOptions';

//page templates
@import 'pageTemplates/diet';
@import 'pageTemplates/diet-new';
@import 'pageTemplates/dietSettings';
@import 'pageTemplates/login';
@import 'pageTemplates/measurement';
@import 'pageTemplates/settings';
@import 'pageTemplates/shoppingList';
@import 'pageTemplates/infoPages';
@import 'pageTemplates/cmsContent';
@import 'pageTemplates/contact';
@import 'pageTemplates/articles';
@import 'pageTemplates/article';
@import 'pageTemplates/error';
@import 'pageTemplates/authConfirm';
@import 'pageTemplates/activities';
@import 'pageTemplates/promoCode';
@import 'pageTemplates/quiz';
@import 'pageTemplates/coupon';
@import 'pageTemplates/newsletter-unsubscribe';
@import 'pageTemplates/cart';
@import 'pageTemplates/priceList';
@import 'pageTemplates/thankYou';
@import 'pageTemplates/workouts';
@import 'pageTemplates/hydration';
@import 'pageTemplates/update';
@import 'pageTemplates/favMeals';

//utils
@import 'envIndicator';
