html {
    height: 100%;
}

body {
    font-family: 'Ubuntu', sans-serif;
    color: $font-color;
    //to enable image right-left
    overflow-x: hidden;
    height: 100%;
}

main{
    background-color: $light-grey2;

    position: relative;

    .view{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        z-index: 20;
    }

    .view-enter {
        transform: translateX(100%);
    }

    .view-enter-active {
        transform: translateX(0);
        transition: transform 500ms;
    }

    .view-exit {
        transform: translateX(0);
    }

    .view-exit-active {
        transform: translateX(100%);
        transition: transform 500ms;
    }

    .bg-grey{
        background-color: $light-grey;
    }

    .bg-light-grey{
        background-color: $light-grey2;
    }

    .bg-white{
        background: $white;
        border-bottom: 1px solid $white;
    }

    .modalContainer{
        transition: transform 500ms;
        transform: translateX(0);
        height: 100%;
    }

    .showModal{
        transform: translateX(-50%);
        overflow: hidden !important;
    }
}

section {
    padding: 3.5rem 0;
    //border-bottom: 2px solid $light-blue;

    @include media-breakpoint-up(lg) {
        padding: 6.563rem 0;
    }

    &.border {
        border-bottom: 2px solid $light-blue;
    }
}

.container {
    @include media-breakpoint-up(sm) {
        padding: 0;
    }
}

.no-gutters {
    margin-right: -15px;
    margin-left: -15px;
    @include media-breakpoint-up(sm) {
        margin-right: 0;
        margin-left: 0;
    }
}

.clearfix {
    clear: both;
}

.h100 {
    height: 100vh;
}

.h100-timeline {
    height: calc(100vh - 70px);
}

.white-box{
    background: $white;
    box-shadow: 0px 0px 10px rgba($black,0.15);
    border-radius: 10px;
    padding: 1rem;

    @include media-breakpoint-up(lg) {
        padding: 2rem;
    }
}

//preloader
.loader-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba($light-grey2, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: $zIndexLoader;

    &.no-fixed {
        position: absolute;
    }

    img {
        flex: 0 1 auto;
    }

    .spinner-border {
        border: 0.25rem solid $secondary;
        border-right-color: transparent;
    }
}

//placeholder
.placeholder {
    background: $light-grey;
    background-image: linear-gradient(
        to right,
        $light-grey 0%,
        darken($light-grey, 4%) 20%,
        $light-grey 40%,
        $light-grey 100%
    );
    background-repeat: no-repeat;
    background-size: 960px 480px;

    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeholderShimmer;
    animation-timing-function: linear;

    color: transparent;

    &:before {
        display: none;
    }
}

@keyframes placeholderShimmer {
    0% {
        background-position: -480px 0;
    }

    100% {
        background-position: 960px 0;
    }
}

.modal-content {
    border-radius: rem-calc(10);
    border: none;

    .modal-header {
        padding: 1rem 1.5rem 0 1.5rem;
        border: none;
    }

    .modal-body {
        padding: 2rem 1.5rem;

        h3 {
            font-size: rem-calc(18);
            color: $dark-grey;
        }

        p {
            font-size: rem-calc(15);
            color: $grey;
            margin-bottom: 2rem;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .fast-code{
            color: rgba($purple, 0.5);

            strong{
                color: $purple;
            }
        }

        .diet-phase{
            display: inline-block;
            font-size: rem-calc(14);
            padding: 0.25rem 1rem;
            border-radius: rem-calc(10);
            border: 1px solid;
            background: $white;

            &.start, &.exit{
                border-color: $orange-yellow;
                color: $orange-yellow;
            }

            &.fast{
                border-color: $purple;
                color: $purple;
            }

            &.health{
                border-color: $green;
                color: $green;
            }

            &.share-button{
                border-color: $purple;
                background: $purple;
                color: $white;
                min-width: auto;
                height: auto;
                background-image: url(../assets/icons/share_icon.svg);
                background-repeat: no-repeat;
                background-position: left 0.25rem center;
                background-size: 2rem;
                padding-left: 2.25rem;
            }
        }
    }

    .modal-footer {
        justify-content: center;
        border-top: 0;
        padding: 0 1.5rem 2rem 1.5rem;
    }
}

.svg-inline {
    display: inline-block;
    font-size: inherit;
    height: 1em;
    overflow: visible;
    vertical-align: -0.125em;
    max-width: 1.5rem; // ie11 hack
}

.SVGInline {
    svg {
        font-size: inherit;
        height: 1em;
        max-width: 1.5rem; // ie11 hack
        vertical-align: -0.125em;
    }

    &.auto-height {
        svg {
            height: auto;
        }
    }
}

// .stay-together {
//     white-space: nowrap;

//     &:before {
//         content: ' ';
//     }
// }

.stay-together {
    position: absolute;
    top: 0;
    left: 0;
}

.full-height {
    height: 100vh;
}

.timeline-new {
    position: relative;

    .timeline-arrow {
        background: none;
        box-shadow: none;
        border: none;
        outline: none;
        height: 100%;
        position: absolute;
        color: $secondary;
        display: flex;
        align-items: center;

        &.left {
            left: 0;
        }

        &.right {
            right: 0;
        }

        .SVGInline {
            svg {
                font-size: 1.25rem;
            }
        }
    }

    .new-timeline {
        white-space: nowrap;
        margin: 0 2rem;
        padding: 0.5rem 0;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;

        &::-webkit-scrollbar {
            display: none;
            opacity: 0;
        }

        &::-webkit-scrollbar-thumb {
            background: $white;
            opacity: 0;
        }

        @include media-breakpoint-up(sm) {
            overflow: hidden;
        }

        &.smooth {
            scroll-behavior: smooth;
        }

        .nav-item {
            display: inline-block;

            .nav-link {
                min-width: 5.5em;
                text-align: center;
                border-radius: 2rem;
                color: $grey;
                margin: 0;
                white-space: nowrap;
                cursor: pointer;
                font-size: rem-calc(12);

                &.active {
                    background: $primary;
                    color: $white;
                }

                &.clicked {
                    background: $light-grey;
                }

                &.placeholder {
                    color: transparent;
                }
            }
        }
    }
}

.print-table {
    margin: 2rem auto 0 auto;

    @media print {
        margin: auto;
    }

    thead {
        @media print {
            display: table-header-group;
        }
    }

    tfoot {
        @media print {
            display: table-footer-group;
        }
    }
}
