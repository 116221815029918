h1 {
    font-family: 'Berkshire Swash', sans-serif;
    color: $black;
    font-size: rem-calc(36);
    margin-bottom: 1.5rem;
}

h2 {
    color: $primary;
    font-size: rem-calc(24);
    margin-bottom: 1rem;

    @include media-breakpoint-up(sm) {
        font-size: rem-calc(24);
    }
}

h3 {
    color: $secondary;
    font-size: rem-calc(18);
    margin-bottom: 1rem;
}

h4 {
    color: $black;
    font-size: rem-calc(16);
    margin-bottom: 1rem;
}

p {
    font-size: 1rem;

    &.grey{
        color: $grey;
    }

    &.bold {
        font-weight: 700;
    }
}

a {
    word-wrap: break-word;

    &.grey {
        color: $grey;
    }
}

ul.list {
    font-size: 1.5rem;
    padding: 0;
    list-style: none;

    li {
        position: relative;
        padding: 0 0 0 2rem;

        &:before {
            content: '';
            position: absolute;
            top: 0.875rem;
            left: 0;
            width: 0.625rem;
            height: 0.625rem;
            background-color: $blue;
            border-radius: 50%;
        }
    }
}

.primary{
    color: $primary;
}

.secondary{
    color: $secondary;
}

.font-color {
    color: $font-color;
}

.grey{
    color: $grey;
}
