@import "~react-lazy-load-image-component/src/effects/blur.css";

.page-template-article-list {
    main{
        margin: 0;
        padding-bottom: 1px;
    }

    header {
        padding: 0;
        background: $white;

        @include media-breakpoint-up(md) {
            padding: 2rem 0;
        }

        h1{
            margin: 0;
        }
    }

    .categoryFilter {
        border-bottom: 1px solid $light-grey;
        border-top: 1px solid $light-grey;
        background: $white;
        margin-bottom: 2rem;

        .nav{
            display: block;
            text-align: center;
            white-space: nowrap;
            margin: 0;
            padding: 0;
            overflow-x: auto;

            .nav-item{
                display: inline-block;
            }
            
            .nav-link{
                padding: rem-calc(12);
                color: $grey;
                font-size: rem-calc(16);
                cursor: pointer;
                border-bottom: 2px solid transparent;
                border-radius: 0;
                background: none;
    
                &:active, &:hover{
                    color: $grey;
                }
    
                &.active{
                    color: $primary;
                    border-bottom: 2px solid $primary
                }
            } 

        }
    }

    .card-deck {
        margin-bottom: 0px;
        @include media-breakpoint-up(sm) {
            margin-bottom: 30px;
        }
    }

    .card {
        text-decoration: none;
        border-radius: 0;
        overflow: hidden;
        border: none;
        cursor: pointer;
        max-width: 100%;
        transition: all 0.3s ease-in-out;
        box-shadow: 0 0 10px rgba($black, 0.15);
        border-radius: $borderRadius;

        @include media-breakpoint-up(sm) {
            max-width: calc(33.3334% - 30px);
        }

        &.placeholder {
            height: 20rem;
            cursor: default;
        }

        .thumbImg {
            position: relative;
            overflow: hidden;
            border-radius: $borderRadius $borderRadius 0 0;

            span{
                border-radius: $borderRadius $borderRadius 0 0;
                overflow: hidden;
            }
        }

        .card-body {
            padding: 1rem;

            .category {
                font-size: rem-calc(14);
                display: inline-block;
                padding: 0.35rem 1rem;
                border-radius: $borderRadius;
                color: $white;
                background: $grey;
                margin: 0 0 1rem;

                &.dieta {
                    background-color: $green;
                }

                &.motywacja {
                    background-color: $purple;
                }

                &.porady {
                    background-color: $orange;
                }

                &.wykłady {
                    background-color: $orange-yellow;
                }
            }

            h3 {
                color: $black;
            }
        
            p {
                color: $font-color;
                overflow: hidden;

                // trim to 2 lines
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;  
            }

            a{
                color: $secondary;
            }
        }
    }
}
