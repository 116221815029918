@charset "UTF-8";

.page-template-diet-new {
    height: 100%;

    main{
        background: $white;

        .white-box{
            border-radius: 10px;
            padding: 1rem;
            overflow: hidden;
        
            @include media-breakpoint-up(lg) {
                padding: 24px;
            }
        }

        .diet-phase{
            display: inline-block;
            font-size: rem-calc(14);
            padding: 0.25rem 1rem;
            border-radius: rem-calc(10);
            border: 1px solid;
            background: $white;

            @media (max-width: 370px) {
                font-size: rem-calc(13);
            }

            &.start, &.exit{
                border-color: $orange-yellow;
                color: $orange-yellow;
            }

            &.fast{
                border-color: $purple;
                color: $purple;
            }

            &.health{
                border-color: $green;
                color: $green;
            }

            &.share-button{
                border-color: $purple;
                background: $purple;
                color: $white;
                min-width: auto;
                height: auto;
                background-image: url(../../assets/icons/share_icon.svg);
                background-repeat: no-repeat;
                background-position: left 0.25rem center;
                background-size: 2rem;
                padding-left: 2.25rem;
            }
        }

        header{
            padding: 1rem 0;

            h1{
                font-family: 'Ubuntu', sans-serif;
                color: $font-color;
                font-weight: 400;
                font-size: rem-calc(30);
                margin: 0 0 0.75rem 0;
            }
    
            p.BTW {
                color: $grey;
                margin-bottom: 0.75rem;
            }
        }

        .top-bar{
            background: $white;

            .btn{
                position: absolute;
            }

            h1{
                font-family: 'Ubuntu', sans-serif;
                color: $font-color;
                font-weight: 400;
                font-size: rem-calc(30);
                margin: 0;
            }
        }
    }

    .add-remove-fav{
        position: absolute;
        top: 0;
        right: 0;
        margin-top: -4px;
        margin-right: 12px;
        padding: 0;
        border: none;
        background: none;

        width: 40px;
        height: 40px;

        img{
            width: 100%;
            height: auto;
        }

        @include media-breakpoint-up(md){
            margin-top: -12px;
            margin-right: 28px;
        }
    }

    .meal{
        position: relative;
        padding: 0 0 16px 0;

        h2{
            font-size: 20px;
            font-weight: 500;
            color: $black;
            margin-bottom: 12px;
        }

        .embed-responsive{
            position: relative;
            margin: -16px -16px 16px -16px;
            width: calc(100% + 32px);
            border-radius: 0;

            @include media-breakpoint-up(md){
                margin: -24px 0 -24px -24px;
                width: calc(100% + 24px);
                height: calc(100% + 48px);

                &::before{
                    padding-top: 0;
                }
            }

            .embed-responsive-item{
                background-size: cover;
                background-position: center;
            }
        }

        .meal-title{
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            margin: 0 0 16px 0;
            min-height: 56px;
           
            h3{
                font-size: 22px;
                font-weight: 500;
                color: $black;
            }

            img{
                margin-top: 3px;
            }
        }
    }

    // meal view
    .meal-navigation{
        position: sticky;
        top: 0;
        background: $white;
        padding: 0;
        z-index: 10;
        overflow-x: auto;

        &::before{
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background: $grey;
            z-index: -1;
        }

        &.active {
            transition: all 0.4s ease-in-out;
        }
        
        &.hidden {
            top: 0;
            transition: all 0.4s ease-in-out;
        }

        .container{
            padding: 0;
        }

        ul{
            text-align: center;
            white-space: nowrap;
            margin: 0;
            padding: 0;
            overflow-x: auto;

            &::-webkit-scrollbar {
                display: none; // Safari and Chrome
            }

            @include media-breakpoint-up(sm) {
                overflow: hidden;
            }

            .nav-item{
                display: inline-block;
            }
                
        }

        .nav-link{
            padding: 12px 12px 10px 12px;
            color: $grey;
            margin: 0;
            font-size: 16px;
            font-weight: 400;
            white-space: nowrap;
            cursor: pointer;
            border-bottom: 2px solid transparent;

            @include media-breakpoint-up(md){
                padding: 12px 24px 10px 24px;
            }

            &:active, &:hover{
                color: $grey;
            }

            &.active {
                color: $primary;
                border-color: $primary;
            }
        }
    }
    
    //meal-details
    .meal-details {
        position: relative;
        padding-top: 0;
        padding-bottom: 0;

        @include media-breakpoint-up(md){
            padding-top: 1.5rem;
        }

        .embed-responsive{
            margin: 0 -15px;
            width: calc(100% + 30px);
            border-radius: 0;

            @include media-breakpoint-up(md){
                margin: 0;
                width: 100%;
                border-radius: 10px;
            }

            .video{
                background-color: $grey;
            }

            .embed-responsive-item{
                background-size: cover;
                background-position: center;
            }
        }

        .meal-info{
            position: relative;
            padding-top: 24px;
            font-size: 14x;
            height: 100%;

            @include media-breakpoint-up(md){
                font-size: 18px;
                padding-top: 0;
            }

            h1{
                font-family: 'Ubuntu', sans-serif;
                color: $black;
                font-weight: 400;
                font-size: rem-calc(30);
            }

            .macro-and-kcal{
                background: $green-opacity;
                padding: 1rem;
                border-radius: $borderRadius;
                margin: 0 0 24px 0;
                color: $green;
                font-size: 12px; 
        
                h3{
                    font-size: 16px;
                    color: $green;
                    font-weight: 400;

                    strong{
                        font-weight: 600;
                    }
                }

                hr{
                    border-top: 1px solid rgba($additional-light-green, 0.5);
                }
        
                .border-right {
                    border-right: 1px solid rgba($additional-light-green, 0.5) !important;
                }
            }

            .action-buttons{
                position: sticky;
                top: 100%;
            }
        }

        .dish{
            @include media-breakpoint-up(md){
                border-top: 1px solid $light-grey;
                margin-top: 24px;
                padding-top: 32px;
            }

            h2{
                color: $black;
                font-size: 24px;
                font-weight: 500;
            }
        }

        .ingredients {
            font-size: 18px;
            list-style: none;
            margin: 0 0 2rem 0;
            padding: 0;

            li {
                position: relative;
                margin: 0 0 16px 0;
                padding-left: 24px;
                cursor: pointer;
            }
        }

        .btn-change {
            vertical-align: middle;
            display: inline-block;
            margin: -2px 8px 0 0;
            width: 20px;
            height: 20px;
            padding: 0;
            background: none;
            border: none;
            outline: none;

            .img-fluid {
                vertical-align: top;
            }
        }

        .collapse, .collapsing{
            margin-left: -24px;
        }

        .card {
            position: relative;
            margin-top: 16px;
            
            border-radius: 10px;
            border: 1px solid $secondary;
            background: $white;
            z-index: 0;

            .card-body {
                padding: 16px;
                font-size: 16px;
                color: $secondary;
                text-align: left;

                .ingredients {
                    text-align: left;
                    color: $font-color;
                    font-size: 16px;
                    margin: 0;

                    li{
                        padding-left: 24px;
                    }

                    button {
                        color: $font-color;
                    }
                }
            }
        }

        .recipe {
            background: $light-grey2;
            padding: 20px;
            margin: 0 0 2rem 0;
            border-radius: $borderRadius;

            @include media-breakpoint-up(md){
                margin: 0 0 1rem 0;
            }

            h4{
                font-family: 'Ubuntu', sans-serif;
                color: $grey;
                font-weight: 400;
                font-size: 18px;
                margin: 0 0 1rem 0;
            }

            ol {
                font-size: 15px;
                padding: 0 0 0 20px;
                margin: 0;
                
                li {
                    position: relative;
                    margin-bottom: 16px;

                    &:last-child{
                        margin-bottom: 0;
                    }
                }
            }
        }

        footer{
            position: sticky;
            bottom: 0;
            width: 100%;
            padding: 8px 0 !important;
            background: $white;
            box-shadow: 0px -4px 6px rgba($black, 0.1);
            border-top: none;
        }
    }

    .change-meal{
        h3{
            font-size: 20px;
            color: $pink;
        }

        .meal-replacement{
            padding: 10px 24px 10px 10px !important;
            font-size: 16px;
            margin: 0 0 20px 0;
            color: $grey1;

            &.favMeal{
                border: 1px solid $primary;
            }
            
            .embed-responsive{
                margin: 0 !important;
                width: 100% !important;
                height: 100% !important;
                border-radius: $border-radius;

                .embed-responsive-item{
                    background-size: cover;
                    background-position: center;
                }
            }

            .col-8{
                height: 84px;
                padding-left: 0;
            }

            .col-9{
                max-height: 84px;
                overflow: hidden;

                // trim to 3 lines
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;  

                @include media-breakpoint-up(md){
                    -webkit-line-clamp: 2;
                }
            }
        }
    }

    .change-day{
        padding-bottom: 1rem;
        
        h3{
            font-size: 20px;
            font-weight: 600;
        }

        input[type="search"]{
            background: $white;
        }

        hr{
            margin: 32px 0;
        }

        label{
            font-weight: 500;
        }

        .white-box{
            margin: 0 0 30px 0;
            color: $grey1;

            &.holiday{
                border: 1px solid $primary;
            }
        }

        .form-control {
            height: auto;
            color: #555;

            background: #F9F9F9 !important;
            border: 1px solid #B4C7DC;
            border-radius: 10px;
        }

        .event-meal{
            border-radius: $border-radius;
            overflow: hidden;
            margin: 0 0 12px 0;
            font-size: 16px;
            padding: 0;

            .embed-responsive{
                margin: 0 !important;
                width: 100% !important;
                // height: 100% !important;
                border-radius: $border-radius;

                .embed-responsive-item{
                    background-size: cover;
                    background-position: center;
                }
            }

            .col-9{
                height: 76px;
                padding-left: 0;

                @include media-breakpoint-up(lg){
                    display: flex;
                    align-items: center;
                    height: 112px;
                }

                div{
                    max-height: 76px;
                    overflow: hidden;
                    padding-top: 4px;

                    // trim to 2 lines
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;  

                    @include media-breakpoint-up(md){
                        padding-top: 0;
                        max-height: 93px;
                    }
                }
            }
        }

        .list-of-dishes{
            margin: 0;
            padding: 0;
            list-style: none;
            display: inline;

            li{
                display: inline;
            }
        }
    }

    .done-icon{
        position: absolute;
        top: 6px;
        left: 20px;
    }
}

.modal-dialog.favourites{
    .modal-content{
        border-radius: 10px;
        border: none;
        text-align: center;

        .modal-header{
            border-bottom: none;
            padding: 20px 20px 10px 20px;
        }

        .modal-body{
            padding: 0 20px;
        }

        .modal-footer{
            border-top: none;
            justify-content: center;
            padding: 20px;
        }
    }
}
