.page-template-diet {
    //page-break-after: always;

    section {
        padding: 3.5rem 0 1.5rem 0;

        @include media-breakpoint-up(lg) {
            padding: 5rem 0;
        }

        @media print {
            padding: 2rem 0 0 0;
        }
    }

    .meal-navigation{
        position: sticky;
        top: 51px;
        background: $white;
        padding: 0;
        z-index: 10;
        border-bottom: 1px solid $grey2;

        &.active {
            transition: all 0.4s ease-in-out;
        }
        
        &.hidden {
            top: 0;
            transition: all 0.4s ease-in-out;
        }

        .nav-link{
            padding: 0.5rem;
            color: $grey;
            font-size: rem-calc(14);
            margin-bottom: -1px;
            cursor: pointer;
            border-bottom: 2px solid transparent;

            &:active, &:hover{
                color: $grey;
            }

            &.active{
                color: $primary;
                border-bottom: 2px solid $primary
            }
        } 
    }

    header {
        padding: 1rem 0 0 0;

        .alert {
            p {
                display: inline;
            }

            .SVGInline {
                font-size: 25px;
            }
        }

        h1{
            font-family: 'Ubuntu', sans-serif;
            color: $font-color;
            font-weight: 400;
            font-size: rem-calc(30);
            margin: 0 0 0.25rem 0;
        }

        p.BTW {
            color: $grey;
            margin-bottom: 0.75rem;
        }

        .diet-phase{
            display: inline-block;
            font-size: rem-calc(14);
            padding: 0.25rem 1rem;
            border-radius: rem-calc(10);
            border: 1px solid;
            background: $white;

            @media (max-width: 370px) {
                font-size: rem-calc(13);
            }

            &.start, &.exit{
                border-color: $orange-yellow;
                color: $orange-yellow;
            }

            &.fast{
                border-color: $purple;
                color: $purple;
            }

            &.health{
                border-color: $green;
                color: $green;
            }

            &.share-button{
                border-color: $purple;
                background: $purple;
                color: $white;
                min-width: auto;
                height: auto;
                background-image: url(../../assets/icons/share_icon.svg);
                background-repeat: no-repeat;
                background-position: left 0.25rem center;
                background-size: 2rem;
                padding-left: 2.25rem;
            }
        }
    }

    .meal {
        padding: 1rem 0;
        position: relative;

        @media print {
            //page-break-inside: avoid;
            border-bottom: none;
            padding: 0;
        }

        .white-box{
            @include media-breakpoint-up(lg) {
                padding: 4rem;
            }
        }

        .add-remove-fav{
            border: none;
            background: none;
            color: $secondary;
            font-size: 15px;
            font-weight: 500;
        }

        h2 {
            position: relative;
            font-weight: 400;
            line-height: rem-calc(36);

            strong{
                font-weight: 500;
            }

            @media print {
                margin: 0;
            }

            .collapse-arrow {
                position: absolute;
                right: 0;
                top: 0;
                color: $orange;
                background: $white;
                border: none;
                transition: all 0.3s ease-in-out;

                &:active, &:hover{
                    color: $orange;
                    background: $white;
                    box-shadow: none !important;
                }

                &:focus{
                    box-shadow: none;
                }

                &.open {
                    transform: rotate(180deg);
                }
            }
        }

        h2.dishName {
            color: $secondary;
            font-weight: 500;
        }

        h3 {
            @media print {
                margin-bottom: 0;
            }
        }

        p.BTW {
            color: $grey;
            margin: 0;
        }

        .info {
            display: block;
            font-size: 1.375rem;
            color: $grey;
            margin-bottom: 2rem;
            float: right;
        }

        .ingredients {
            font-size: 1rem;
            list-style: none;
            margin: 0 0 2rem 0;
            padding: 0;

            @include media-breakpoint-up(md) {
                margin: 0 1rem 3rem 0;
            }

            li {
                margin: 0.5rem 0;
                cursor: pointer;

                @media print {
                    margin: 0;
                }
            }

            .card-body {
                .btn-change {
                    .img-sup {
                        vertical-align: super;
                    }
                }
            }
        }

        .btn-change {
            vertical-align: middle;
            display: inline-block;
            margin: 0;
            width: 2.25rem;
            height: 2.25rem;
            padding: 0.375rem;
            background: none;
            border: none;
            outline: none;

            .img-fluid {
                vertical-align: top;
            }
        }

        .card {
            position: relative;
            margin-top: 1rem;
            border-radius: 10px;
            border: 1px solid transparent;
            background: $secondary;
            z-index: 0;

            &:after {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background: $white;
                z-index: -1;
                border-radius: 9px;
            }

            .card-body {
                padding: 1rem;
                font-size: 1rem;
                color: $secondary;

                .ingredients {
                    text-align: left;
                    color: $font-color;
                    font-size: 1rem;
                    margin: 0;

                    button {
                        position: relative;
                        background: none;
                        border: none;
                        color: $font-color;
                        // padding-left: 30px !important;

                        // .fav-icon{
                        //     position: absolute;
                        //     left: 0;
                        //     top: 2px;
                        // }
                    }
                }
            }
        }

        .recipe {
            background: $light-grey;
            padding: 1rem;
            margin: 0 0 2rem 0;
            border-radius: 10px;

            h2{
                font-size: rem-calc(18);
                color: $grey;
            }

            ol {
                font-size: 1rem;
                padding: 0 0 0 1.25rem;

                li {
                    margin-bottom: 1rem;

                    @media print {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    .summary {
        padding: 0.5rem 0 1.5rem 0;

        h3 {
            font-size: 1.25rem;

            @include media-breakpoint-up(md) {
                font-size: 1.75rem;
            }

            .big-number {
                font-size: 2.75rem;
                vertical-align: top;
                line-height: 1;

                @include media-breakpoint-up(md) {
                    font-size: 3.75rem;
                }
            }
        }

        .btn-print {
            display: block;
            font-size: 1.375rem;
            color: $grey;
            float: right;
            text-decoration: underline;
        }

        
        .ingredients {
            font-size: 1.5rem;
            list-style: none;
            margin: 0 1rem 0 0;
            padding: 0;

            li {
                margin: 1rem 0;
            }
        }

        .diet-phase{
            display: inline-block;
            font-size: rem-calc(14);
            padding: 0.25rem 1rem;
            border-radius: rem-calc(10);
            border: 1px solid;
            margin-bottom: 1rem;
    
            &.start, &.exit{
                border-color: $orange-yellow;
                color: $orange-yellow;
            }
    
            &.fast{
                border-color: $purple;
                color: $purple;
            }
    
            &.health{
                border-color: $green;
                color: $green;
            }
        }

        .not-active{
            color: $grey;

            .diet-phase{
                &.fast{
                    border-color: $grey;
                    color: $grey;
                }   
            }
        }

        .btn-change {
            vertical-align: middle;
            display: inline-block;
            margin: 0;
            width: 2.25rem;
            height: 2.25rem;
            padding: 0.375rem;

            .img-fluid {
                vertical-align: top;
            }
        }

        .card {
            position: relative;
            margin-top: 1rem;
            border-radius: 0.3125rem;
            border: 1px solid transparent;
            background: $secondary;
            background-size: 200% auto;
            z-index: 0;

            &:after {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background: $white;
                z-index: -1;
                border-radius: 0.25rem;
            }

            .card-body {
                padding: 1rem;
                font-size: 1rem;
                text-align: left;
                color: $secondary;

                .ingredients {
                    font-size: 1rem;
                    margin: 0;
                    color: $font-color;
                    text-align: left;

                    .SVGInline {
                        font-size: 30px;
                    }
                }

                .list-of-dishes {
                    list-style-type: none;
                    display: inline;
                    margin: 0;
                    padding: 0;

                    li {
                        display: inline;
                    }
                }
            }
        }
    }
}

//Animations

@keyframes rotateLeft {
    from {
        transform-origin: center;
        transform: translate3d(0, 0, 1, 0);
    }

    to {
        transform-origin: center;
        transform: rotate3d(0, 0, 1, -540deg);
    }
}

.rotateLeft {
    animation-name: rotateLeft;
}

@keyframes rotateRight {
    from {
        transform-origin: center;
        transform: rotate3d(0, 0, 1, -540deg);
    }

    to {
        transform-origin: center;
        transform: rotate3d(0, 0, 0);
    }
}

.rotateRight {
    animation-name: rotateRight;
}
