footer{
    background: $white;
	position: relative; 
    padding: 3.5rem 0;
    border-top: 1px solid $light-grey;
	
	@include media-breakpoint-up(sm) {
		padding: 5rem 0;
	}

	h1{
		font-size: rem-calc(24);
	}

	.nav-link{
		font-size: 1rem;
		color: $font-color;
		padding: 0 0 1rem 0;
	}

	img{
		margin: 0 1rem 1rem 0;
	}

	.avalibility{
		opacity: 0.3;
		font-size: rem-calc(12);
		margin-right: 0;
		margin-bottom: 1rem;
		display: inline-block;
	}

	hr{
		border-top: 1px solid $light-grey2;
	}
}