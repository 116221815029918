//colors
$black: #000;
$white: #fff;

$dark-grey: #454545;
$grey1: #6D6D6D;
$grey: #989898;
$grey2: #C9C9C9;
$light-grey: #ededed;
$light-grey2: #f3f3f3; 
$light-grey3: #DFDFDF;

$orange-yellow: #FF7F00;

$dark-orange: #d8461e;
$orange: #f05930;
$light-orange: #f05930;
$very-light-orange: #eccdc4;

$orange-opacity: #FFC1B11C;

$dark-green: #167135;
$green: #0d9136;
$light-green: #219a49;
$additional-light-green: #3FC769;
$very-light-green: #b2e299;

$green-opacity:#B8F4CA55;

$input-border: #C9C9C9;
$input-bg: #F8F8F8;

$font-color: $dark-grey;
$primary: $orange;
$secondary: $green;

$blue: #48b3e3;

$red: #e5424a;
$magenta: #e44ba3;
$pink: #e56d9e;
$purple: #B912A5;

$navy-blue: #044389;
$light-blue: #aeddfb;
$violet: #9872b5;

$borderRadius: 10px;

//env indicator
$devColor: $blue;
$stageColor: $green;
$prodColor: $red;
// In italics “v” from “dev” sticks out from the container tad too much
$italicDevCompensation: 1.1rem;

$zIndexEnvIndicator: 16777271;
$zIndexCacheMessage: 1001;
$zIndexFloatingACtion: 1001;
$zIndexFooter: 1000; // .fixed-top (main menu) has 1030
$zIndexMenu: 100;
$zIndexTimeline: 5;
$zIndexLoader: 1;
