.page-template-settings {
    header {
        padding: 0 0 2rem 0;

        @include media-breakpoint-up(md) {
            padding: 2rem 0;
        }
    }

    .form-group{
        label{
            color: $secondary;
            font-size: rem-calc(16);
        }
    }

    .custom-control-input:focus ~ .custom-control-label::before {
        box-shadow: none;
    }

    // .custom-control-label::before {
    //     // border: 1px solid $secondary;
    // }

    .custom-control-input{
        &:focus ~ .custom-control-label::before {
            border-color: $secondary;
        }

        &:active ~ .custom-control-label::before {
            background-color: $green-opacity;
            border-color: $secondary;
        }

        &:checked ~ .custom-control-label::before {
            border-color: $secondary;
            background-color: $secondary;
        }
    }

    .btn{
        padding: 0 1rem;

        @include media-breakpoint-up(md) {
            padding: 0 2rem;
        }
    }
}
